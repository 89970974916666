/**
 * @generated SignedSource<<bac68448b5904b206494367deaea3f4d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ItemHeartWrapper_item$data = {
  readonly heartPortfolioItems?: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"Heart_heartPortfolioItems">;
  } | null> | null;
  readonly serviceId: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"Heart_item">;
  readonly " $fragmentType": "ItemHeartWrapper_item";
};
export type ItemHeartWrapper_item$key = {
  readonly " $data"?: ItemHeartWrapper_item$data;
  readonly " $fragmentSpreads": FragmentRefs<"ItemHeartWrapper_item">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "loadPortfolioData"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "showHeartCount"
    },
    {
      "defaultValue": "",
      "kind": "LocalArgument",
      "name": "userId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ItemHeartWrapper_item",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "serviceId",
      "storageKey": null
    },
    {
      "condition": "showHeartCount",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "Heart_item"
        }
      ]
    },
    {
      "condition": "loadPortfolioData",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": "heartPortfolioItems",
          "args": [
            {
              "kind": "Literal",
              "name": "portfolioType",
              "value": "HEART"
            },
            {
              "kind": "Variable",
              "name": "userId",
              "variableName": "userId"
            }
          ],
          "concreteType": "PortfolioItemV2",
          "kind": "LinkedField",
          "name": "portfolioItems",
          "plural": true,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "Heart_heartPortfolioItems"
            }
          ],
          "storageKey": null
        }
      ]
    }
  ],
  "type": "Item",
  "abstractKey": null
};

(node as any).hash = "a1273d8f152f6a140754aac0d39779c0";

export default node;
