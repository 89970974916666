import { createRefetchContainer, graphql } from 'react-relay/legacy';
import PropTypes from 'prop-types';
import Folder from '../src/components/Folder';

function ItemFolderWrapper(props) {
    function doRefetch() {
        return new Promise(resolve => {
            props.relay.refetch(
                {
                    itemId: props.item.serviceId,
                    userId: props.userId,
                    loadPortfolioData: true,
                },
                null,
                () => resolve()
            );
        });
    }
    return (
        <Folder
            {...props}
            doRefetch={doRefetch}
            itemId={props.item.serviceId}
            selectedItemIds={props.item.serviceId ? [props.item.serviceId] : []}
            folderPortfolioItems={props.item.folderPortfolioItems || []}
        />
    );
}

ItemFolderWrapper.propTypes = {
    relay: PropTypes.object.isRequired,
    userId: PropTypes.string,
    item: PropTypes.object.isRequired,
};

export default createRefetchContainer(
    ItemFolderWrapper,
    {
        item: graphql`
            fragment ItemFolderWrapper_item on Item
            @argumentDefinitions(
                loadPortfolioData: { type: "Boolean", defaultValue: false }
                userId: { type: "String", defaultValue: "" }
            ) {
                serviceId
                folderPortfolioItems: portfolioItems(userId: $userId, portfolioType: FOLDER)
                    @include(if: $loadPortfolioData) {
                    ...Folder_folderPortfolioItems
                }
            }
        `,
    },
    graphql`
        query ItemFolderWrapperRefetchQuery(
            $itemId: String!
            $userId: String!
            $loadPortfolioData: Boolean!
        ) {
            viewer {
                item(itemId: $itemId, pageType: PORTFOLIO) {
                    ...ItemFolderWrapper_item
                        @arguments(userId: $userId, loadPortfolioData: $loadPortfolioData)
                }
            }
        }
    `
);
