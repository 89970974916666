import { createRefetchContainer, graphql } from 'react-relay/legacy';
import PropTypes from 'prop-types';
import Heart from '../src/Heart/Heart';

function ItemHeartWrapper(props) {
    const { hideTotalLikesCount = true } = props;
    function doRefetch(callback) {
        props.relay.refetch(
            {
                itemId: props.item.serviceId,
                userId: props.userId,
                loadPortfolioData: true,
                showHeartCount: !hideTotalLikesCount,
            },
            null,
            () => callback()
        );
    }

    return (
        <Heart
            {...props}
            hideTotalLikesCount={hideTotalLikesCount}
            doRefetch={doRefetch}
            itemId={props.item.serviceId}
            item={hideTotalLikesCount ? null : props.item}
            heartPortfolioItems={props.item.heartPortfolioItems || []}
        />
    );
}

ItemHeartWrapper.propTypes = {
    relay: PropTypes.object.isRequired,
    userId: PropTypes.string,
    item: PropTypes.object.isRequired,
    viewer: PropTypes.object.isRequired,
    hideTotalLikesCount: PropTypes.bool,
};

export default createRefetchContainer(
    ItemHeartWrapper,
    {
        item: graphql`
            fragment ItemHeartWrapper_item on Item
            @argumentDefinitions(
                loadPortfolioData: { type: "Boolean", defaultValue: false }
                userId: { type: "String", defaultValue: "" }
                showHeartCount: { type: "Boolean", defaultValue: false }
            ) {
                serviceId
                ...Heart_item @include(if: $showHeartCount)
                heartPortfolioItems: portfolioItems(userId: $userId, portfolioType: HEART)
                    @include(if: $loadPortfolioData) {
                    ...Heart_heartPortfolioItems
                }
            }
        `,
        viewer: graphql`
            fragment ItemHeartWrapper_viewer on Viewer
            @argumentDefinitions(
                loadPortfolioData: { type: "Boolean", defaultValue: false }
                userIds: { type: "[String]", defaultValue: [] }
            ) {
                ...Heart_viewer @arguments(userIds: $userIds, loadPortfolioData: $loadPortfolioData)
            }
        `,
    },
    graphql`
        query ItemHeartWrapperRefetchQuery(
            $itemId: String!
            $userId: String!
            $loadPortfolioData: Boolean!
        ) {
            viewer {
                item(itemId: $itemId, pageType: PORTFOLIO) {
                    ...ItemHeartWrapper_item
                        @arguments(userId: $userId, loadPortfolioData: $loadPortfolioData)
                }
            }
        }
    `
);
